/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { Logout, AuthPage } from "./modules/Auth";
import { ErrorPage } from "./pages/ErrorPage";

const StorePage = lazy(() => import("./store/StorePage1"));
const LoginPage = lazy(() => import("./store/Login"));
// const ErrorsPage = lazy(() => import("./modules/ErrorsExamples/ErrorsPage"));

export function Routes() {
  // const { isAuthorized } = useSelector(
  //   ({ auth }) => ({
  //     isAuthorized: auth.user != null
  //   }),
  //   shallowEqual
  // );
  return (
    <Switch>
      <Route path="/error" component={ErrorPage} />
      <Route
        path="/auth/login/:status?/:auth_token?/:refresh_token?/:user?"
        component={AuthPage}
      />
      <Route path="/error" component={ErrorPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/register" component={LoginPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/" component={StorePage} />
    </Switch>
  );
}
